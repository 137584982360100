@import "../../styles/ms";

div.infomachineGrid {
  @extend %flex1;

  div.linkBack {
    display: flex;
    flex: 1;
    align-items: center;

    p.MuiTypography-body2 {
      font-size: 0.8rem;

      a {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

    margin-bottom: 10px;
  }

  div.title {
    @extend %screenTitle;
    flex: 1;
  }

  div.status {
    flex: 1;

    div.MuiGrid-root {
      align-self: center;
    }

    div.MuiInputBase-root {
      display: flex;

      div.MuiSelect-root {
        display: flex;
        flex-direction: row;

        p {
          margin-left: 5px;
        }

        p,
        svg {
          font-size: 0.8rem;
          align-self: center;
        }
      }
    }

    div.saveButton {
      margin-left: 10px;

      button {
        font-size: 0.5rem;
      }
    }
  }

  div.data {
    @extend %flex1;
    flex: 15;

    > div.MuiGrid-container {
      @extend %flex1;
      flex-direction: row;
      //overflow: hidden;
    }

    h6 {
      font-size: 1rem;
      text-transform: uppercase;
    }

    div.formContainer {
      flex-wrap: unset;
    }
  }
}

ul > li.stateSelect {
  p {
    margin-left: 5px;
  }

  svg,
  p {
    font-size: 0.8rem;
  }
}

#machine-status-text {
  display: inline-flex;
  margin-left: 5px;
  font-size: 20px;
}
