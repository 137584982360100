@import "../../styles/ms";

div.listmachinesShiftGrid {
  div.machineCard {
    margin: 10px;

    span.MuiCardHeader-title {
      font-size: 1.2rem;
    }

    div.MuiCardContent-root {
      padding: 5px 10px;

      p.title {
        font-weight: bold;
      }
    }

    div.drillingLabel,
    div.drillingValue {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: flex-start;
      /*align-items: center;*/
      p.MuiTypography-body2 {
        font-size: 0.6rem;
      }

      font-weight: bolder;
    }

    div.drillingContent {
      margin-bottom: 10px;
    }
  }
}
