@import "../../styles/ms";

div.tableMaintenance {
  @extend %flex1;
  flex: 12;
  margin-bottom: 10px;
  overflow: hidden;

  div.tableContainer,
  div.mainTable,
  div.buttons {
    @extend %flex1;
    flex: 1;
    padding: 10px;

    div.MuiDataGrid-window {
      overflow-x: hidden;
    }

    div.buttons {
      flex: 0.8;
    }

    button {
      width: 20%;
      align-self: flex-end;
      height: 50px;
    }
  }

  div.MuiDataGrid-selectedRowCount {
    visibility: hidden !important;
  }
}
