@import "../../styles/ms";

div.MuiGrid-root.stagecontrolGrid {
  margin: 0.5rem 0;

  div.toggleTaskContainer {
    div.buttonInput {
      button {
        font-size: 0.5rem;
        padding: 0.1rem;
      }
    }
  }

  p.MuiTypography-body2 {
    font-size: 0.8rem;

    &.state_0 {
      color: red;
    }

    &.state_1 {
      color: orange;
    }

    &.state_2 {
      color: yellow;
    }

    &.state_3 {
      color: $ms_green;
    }
  }
}
