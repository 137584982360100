div.dataRow {
  align-items: center;

  svg.MuiSvgIcon-root {
    cursor: pointer;
  }

  p.data {
    text-overflow: clip;
    overflow: hidden;
  }
}

div.dataPopupMachine {
  div.MuiPaper-root {
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.9);
    color: white;
  }
}
