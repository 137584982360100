@import "ms";

html,
body {
  font-size: 1.5vw;
  margin: 0;
  height: 100%;
  @extend %flex1;

  #root {
    @extend %flex1;

    div.app-wrapper {
      @extend %flex1;

      div.loader-container.global {
        //done so it goes on top of the modals
        //modal has z-index:1300
        z-index: 1500;

        span.message {
          font-family: Flexo;
        }
      }

      //global padding for textfield inputs
      input.MuiInput-input {
        padding: 0.5rem 0 0.3rem 0;
      }
    }
  }

  //done here because can be in a modal
  span.MuiFormLabel-asterisk {
    color: red;
  }

  div.MuiDialog-root {
    div.MuiPickersCalendarHeader-switchHeader {
      p.MuiTypography-root {
        font-size: 0.75rem;
      }
    }

    h3.MuiTypography-root,
    h4.MuiTypography-root {
      font-size: 1.2rem !important;
    }

    /* button.MuiButtonBase-root{
       h4.MuiTypography-root,h3.MuiTypography-root{
         font-size:2rem;
       }
     }*/
  }

  //datagrid
  div.MuiDataGrid-window {
    overflow-x: hidden !important;
  }
}
