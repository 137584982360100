@import "../styles/ms";

div.gaugeMain {
  position: relative;
  width: 100%;
  height: 110px;
  text-align: center;

  div.gaugeCenter {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    // background: "#FFFFFF33";
    text-align: center;
    // This is important to preserve the chart interactivity
    pointer-events: none;
  }
}
