@import "../../styles/ms";

div.modalArchives {
  div.MuiDialog-paper {
    div.MuiDialogTitle-root {
      h2 {
        color: black;
        display: flex;
        align-items: center;

        span {
          margin-left: 20px;
        }
      }
    }
  }

  div.tableContainer {
    height: 650px;

    div.reportedFaults {
      @extend %flex1;
      flex-direction: column;

      span.fault {
        display: flex;
        flex: 1;
      }
    }

    div.machineStateGrid {
      p.MuiTypography-root {
        font-size: 1rem !important;
      }
    }

    div.state_1,
    p.state_1 {
      color: orange !important;
    }

    div.state_2,
    p.state_2 {
      color: red !important;
    }
  }
}
