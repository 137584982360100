@import "../../styles/ms";

div.modalError {
  div.MuiDialog-paper {
    width: 25rem;

    div.MuiDialogTitle-root {
      h2 {
        color: $ms_red;
        display: flex;
        align-items: center;

        span {
          margin-left: 20px;
        }
      }
    }

    div.MuiDialogContent-root {
      div.message {
        color: black;
      }

      div.details {
        border: 1px solid black;
        border-radius: 5px;
        padding: 5px;

        pre {
          white-space: normal;
          font-size: 0.5rem;
        }
      }
    }
  }
}
