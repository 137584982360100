@import "../../styles/ms";

div.listmachinesGrid {
  @extend %flex1;

  div.title {
    //@extend %screenTitle;
    display: flex;
    //align-items: center;
    flex: 1;
  }

  div.table {
    @extend %flex1;
    flex: 20;
    margin-bottom: 10px;
    overflow: auto;
  }

  div.button {
    flex: 1;
    align-self: flex-end;
  }

  div.MuiDataGrid-mainGridContainer {
    overflow-x: scroll;
  }

  div.MuiDataGrid-columnHeaderTitle {
    font-size: 0.68rem;
  }

  div.MuiDataGrid-cell {
    font-size: 0.6rem;

    button.buttonEdit,
    button.buttonView {
      span,
      svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }

  div.MuiDataGrid-selectedRowCount {
    visibility: hidden !important;
  }
}
