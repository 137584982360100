@import "../../styles/ms";

div.machineStateGrid {
  display: flex;
  align-content: center;

  &.state_0 {
    color: $ms_green;
  }

  &.state_1 {
    color: orange;
  }

  &.state_2 {
    color: red;
  }

  > div.MuiGrid-item {
    display: flex;
    align-content: center;

    p {
      display: flex;
      align-self: center;
      font-size: 0.6rem;
      @media (max-width: 1000px) {
        display: none;
      }
    }

    svg {
      width: 1rem;
      cursor: pointer;
    }
  }
}

div.machineStatePopover {
  div.MuiPaper-root {
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.9);
    color: white;

    h6.title {
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: bold;
    }
  }
}
