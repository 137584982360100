@import "../styles/ms";

div.MuiContainer-root.boardContainer {
  @extend %flex1;
  width: 100%;
  max-width: 100%;
  margin: 0;

  div.MuiGrid-root.boardGrid {
    @extend %flex1;

    > div.MuiGrid-root {
      @extend %flex1;

      & > div {
        @extend %flex1;

        &.pitList {
          flex: 1;
          overflow-y: hidden;
        }

        &.patternList {
          flex: 12;
        }
      }
    }
  }
}
