@import "../../styles/ms";

div.level {
  p {
    font-weight: bolder;
    font-size: 0.6rem;
  }

  &.reallyLow {
    color: red;
  }

  &.low {
    color: orange;
  }

  &.average {
    color: #85ac17;
  }

  &.good {
    color: $ms_green;
  }
}
