//Millisecond related styles
$ms_purple: #6200ee;
$ms_blue: #508ce6;
$ms_green: #2ac940;
$ms_red: red;

%flex1 {
  display: flex;
  flex: 1;
  flex-flow: column;
  overflow: auto;
}

%card-header-title {
  opacity: 0.85;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.5em;
  float: left;
}

%card-header-button {
  float: right;
}

%screenTitle {
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: bolder;
  color: black;
}

%text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
