@import "../styles/ms";

div.profileContainer {
  @extend %flex1;

  h4 {
    margin-top: 0.5rem;
  }

  button {
    width: 55%;
  }

  ul.MuiList-root {
    li {
      flex-direction: column;
      align-items: flex-start;

      div.langageSelect {
        width: 55%;
      }

      .pwdTextField {
        width: 55%;
      }
    }
  }
}
