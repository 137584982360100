@import "../styles/ms";

header.navbar {
  &.loggedIn {
    background: url("../static/images/navbar_bg.jpg");
  }

  &.notLogged {
    background-color: black;
  }

  a.MuiTab-textColorPrimary {
    color: white !important;
    font-size: 1rem;
    font-weight: bold;
  }

  div.MuiTabs-flexContainer {
    height: 100%;

    a.navbarAuth {
      margin-left: auto;
      color: white;
      width: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }

    a.brand {
      @extend %flex1;
      flex: 1;
      text-decoration: none;
      max-width: 15rem;

      div.mslogo {
        @extend %flex1;
        align-items: center;
        justify-content: center;

        img {
          width: 4rem;
        }
      }

      p.logo {
        @extend %flex1;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
        text-transform: uppercase;
        color: white;
        overflow: hidden;
      }
    }

    a.MuiTab-root {
      display: flex;
      flex: 1;
      max-width: 8rem;
    }
  }

  span.MuiTabs-indicator {
    background-color: $ms_green;
    height: 5px;
  }

  #select-admin-dropdown {
    margin-left: 30px !important;
    color: white !important;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 6px !important;
  }

  .select-dropdown {
    border-bottom: 5px solid #2ac940;
    margin-top: 0px !important;
  }

  .MuiSelect-icon {
    margin: -4px;
  }
}

#menu-items-selected-first {
  background-color: darkgray;
}

#menu-items-selected-second {
  background-color: darkgray;
}
