@import "../../styles/ms";

div.newUserGrid {
  @extend %flex1;

  div.linkBack {
    display: flex;
    flex: 1;
    align-items: center;

    p.MuiTypography-body2 {
      font-size: 0.8rem;

      a {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

    margin-bottom: 10px;
  }

  div.title {
    @extend %screenTitle;
    flex: 1;
  }

  div.form {
    @extend %flex1;
    flex: 15;

    div.MuiFormControl-root {
      label,
      input,
      p {
        font-size: 0.8rem;
      }
    }

    div.buttons {
      justify-content: space-around;

      button {
        margin: 20px;
        margin-left: 0;
      }

      span.MuiButton-label {
        justify-content: center;
        font-size: 0.8rem;
      }
    }
  }
}
