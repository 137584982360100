@import "../styles/ms";

div.MuiContainer-root.fieldContainer {
  @extend %flex1;
  width: 100%;
  max-width: 100%;
  margin: 0;

  div.MuiGrid-root.fieldGrid {
    @extend %flex1;

    > div.MuiGrid-root {
      @extend %flex1;

      & > div {
        @extend %flex1;

        &.patternStages {
          flex: 1.3;
        }

        &.pitList {
          flex: 1;
          overflow-y: hidden;
        }

        &.patternList {
          flex: 8;
        }

        &.patternMap {
          overflow-y: hidden;
        }

        &.patternStatuses {
          flex-direction: row;

          div.MuiGrid-root.buttonGrid {
            padding: 10px;
            flex: 1;
            align-items: flex-start;

            > button {
              margin: 5px;
              width: 5rem;
              height: 1.5rem;
              font-size: 0.5rem;
              line-height: 0.5rem;
            }
          }
        }
      }
    }
  }
}
