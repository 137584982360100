@import "../../styles/ms";

div.modalNewPit {
  div.MuiDialog-paper {
    width: 35rem;

    div.MuiDialogTitle-root {
      h2 {
        color: black;
        display: flex;
        align-items: center;

        span {
          margin-left: 20px;
        }
      }
    }
  }

  p.MuiFormHelperText-root {
    color: red;
  }

  #gridselector {
    height: 75px;
    color: #757575;
    flex-direction: column-reverse;
  }
}
