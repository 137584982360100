@import "../../styles/ms";

div.modalMaintenance {
  div.MuiDialog-paper {
    div.MuiDialogTitle-root {
      h2 {
        color: black;
        display: flex;
        align-items: center;

        span {
          margin-left: 20px;
        }
      }
    }

    div.dataElementDateTime {
      margin: 10px 0px;
    }
  }

  p.MuiFormHelperText-root {
    color: red;
  }

  .service-type-input-label {
    margin-top: 15px;
  }

  .service-type-field-dropdown {
    margin-top: -10px;
  }
}
