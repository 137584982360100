@import "../../styles/ms";

div.MuiContainer-root {
  div.stageSelectorGrid {
    padding: 5px 0px;

    p.MuiTypography-root {
      color: $ms_blue;
      margin: 5px 20px;
      font-weight: bold;
      font-size: 0.8rem;
      cursor: pointer;
      width: 6rem;
      text-align: center;
      text-transform: uppercase;

      &.active {
        border-bottom: 5px solid $ms_blue;
      }
    }
  }
}
