@import "../../styles/ms";

div.MuiContainer-root {
  ::-webkit-scrollbar {
    display: none;
  }

  div.pitselectorGrid {
    overflow: scroll;

    button {
      margin: 5px;
      font-size: 0.6rem;
      min-width: max-content;
      line-height: 0.6rem;
    }
  }

  p.pitselectorTitle {
    font-size: 20px;
    line-height: 1;
  }
}
