@import "../../styles/ms";

div.drillingTaskContainer {
  div.progressSlider {
    margin: 20px;
  }

  div.drillingLabel,
  div.drillingValue {
    display: flex;
    align-items: center;

    p.MuiTypography-body2 {
      font-size: 0.6rem;
    }
  }
}
