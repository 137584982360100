@import "../../styles/ms";

div.modalConfirm {
  div.MuiDialog-paper {
    width: 25rem;

    div.MuiDialogTitle-root {
      h2 {
        color: black;
        display: flex;
        align-items: center;

        span {
          margin-left: 20px;
        }
      }
    }
  }
}
