@import "../../styles/ms";

div.MuiCard-root.patternCardMain {
  div.stagesGrid {
    @extend %flex1;
    flex-direction: row;

    div.stageDetails {
      flex: 1;
      margin: 0px 1rem;
    }
  }
}
