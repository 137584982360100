@import "../styles/ms";

div.MuiContainer-root.loginContainer {
  @extend %flex1;
  width: 100%;
  max-width: 100%;
  margin: 0;
  //https://stackoverflow.com/a/48267760
  background: linear-gradient(rgba(0, 0, 0, 0.4) 100%, rgba(0, 0, 0, 0.4) 100%),
    url("../static/images/login_bg.jpg");
  background-size: cover;

  div.MuiGrid-root.loginGrid {
    text-align: left;
    color: white;
    @extend %flex1;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    text-transform: uppercase;
    overflow: hidden;

    div.MuiGrid-item {
      > * {
        margin: 1rem;
      }

      div.MuiTextField-root {
        width: 47%;

        label,
        input {
          color: white;
        }

        input {
          border-bottom: 1px solid white;
        }

        #login_screen-password {
          border-bottom: none;
        }
      }

      button.MuiButtonBase-root {
        margin-top: 1rem;
        margin-left: 0px;
        width: 35%;
        color: black;
      }
    }
  }

  *.collaborationText {
    color: white;
    font-size: small;
  }

  .textfieldPassword {
    border-bottom: 1px solid #858585;
  }

  .textfieldPassword:hover {
    border-bottom: 1px solid #2b2a2a;
  }

  .login-form-fields {
    margin-left: 0px !important;
  }

  .buttonLostPassword {
    margin-left: 5% !important;
  }
}
