@import "../../styles/ms";

div.MuiGrid-root.stateselectorMain {
  flex: 0.5;

  div.stateselectorGrid {
    button {
      margin: 5px;
      width: 5rem;
      font-size: 0.6rem;
      line-height: 0.6rem;
    }
  }

  p.stateselectorTitle {
    font-size: 20px;
    line-height: 1;
  }
}
