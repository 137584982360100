@import "../../styles/ms";

div.shiftPatternMap {
  margin-top: 10px;
  flex-grow: 1;
  overflow-y: auto;
  min-height: 0px;
  display: flex;
  flex-direction: column;
  position: relative;
  border: 2px solid black;

  div.leaflet-container {
    height: 25rem;
    width: 100%;
    flex-grow: 1;
    overflow: hidden;
    z-index: 10;
  }
}
