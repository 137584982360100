div.modalSurveyImport {
  div.MuiPaper-root {
    div.MuiDialogTitle-root {
      h2 {
        color: black;
        display: flex;
        align-items: center;

        span {
          margin-left: 20px;
        }
      }
    }

    div.MuiDropzoneArea-root {
      height: 6rem;
      //max-height: 5rem;
      min-height: unset;
    }

    div.fileName {
      //visibility: hidden;
    }

    div.resultsGrid {
      padding: 20px;

      div.MuiGrid-item {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      p.MuiTypography-body2 {
        margin-left: 20px;
      }

      div.resultsInfo {
        color: green;
      }

      div.resultsWarning {
        color: orange;
      }

      div.resultsError {
        color: red;
      }
    }
  }
}
