@import "../../styles/ms";

div.infoMachineGrid {
  flex-wrap: unset;

  div.dataElement {
    margin: 5px 0px;
  }

  p {
    &.label {
      font-weight: bolder;
    }

    &.data {
    }
  }

  hr {
    height: 3px;
  }
}

div.formMachineGrid {
  @extend %flex1;
  overflow: hidden;
  flex: 12;

  div.MuiFormControl-root {
    label,
    input,
    p {
      font-size: 0.8rem;
    }
  }

  div.buttonSave {
    margin: 20px;
    margin-left: 0;

    span.MuiButton-label {
      justify-content: center;
      font-size: 0.8rem;
    }
  }

  div.serviceFirst {
    width: 100%;
  }

  p.MuiFormHelperText-root {
    color: red;
  }

  &__secretKeyContainer {
    display: flex;
    flex-direction: row;
  }
}

.secretKeyBtn {
  width: 110px;
  height: 34px;
  margin-top: 25px;
}
