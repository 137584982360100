@import "../../styles/ms";

div.MuiCard-root.patternCardMain {
  margin: 10px;

  &.expanded {
    flex: 2 !important;

    div.expandedPatternData {
      //this is required because for some reason the "unsaved changes" text makes the whole control height increase...
      //max-height:25px;
      p.unsavedChanges {
        font-size: 0.8rem;
        line-height: 0rem;
        color: red;
      }
    }
  }

  &.collapsed {
    flex: 1 !important;
  }

  div.MuiCardHeader-root {
    padding: 10px;
    padding-bottom: 0px;
    text-transform: uppercase;

    span.MuiCardHeader-title {
      font-size: 1rem;
    }
  }

  div.MuiCardContent-root {
    padding-top: 0px;

    div.pitData,
    div.priorityData {
      align-items: center;

      div.MuiSelect-root {
        font-size: 0.8rem;
        line-height: 1rem;
        padding-bottom: 0px;
      }

      p {
        font-size: 0.8rem;
        color: black;
      }
    }

    div.expandedButton {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }
  }

  p.MuiFormHelperText-root {
    color: red;
  }
}
