@import "../../styles/ms";

div.MuiGrid-root.stageDetails {
  div.stageTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $ms_blue;
    text-transform: uppercase;
    padding-bottom: 2px;
    border-bottom: 2px solid $ms_blue;
    margin-bottom: 10px;

    p {
      margin-left: 5px;
      height: 0.8rem;
      font-size: 0.7rem;

      &.active {
        font-weight: bolder;
      }
    }

    svg {
      width: 25px;
    }
  }

  div.task {
    &.completed {
      color: $ms_green;
    }

    &.new {
      color: grey;
    }

    div.icon {
      svg {
        width: 1rem;
        cursor: pointer;
      }
    }

    p {
      &.mainTask {
        font-size: 0.6rem;
        @extend %text-ellipsis;
      }

      &.subTask {
        font-size: 0.5rem;
      }
    }
  }
}
