@import "../../styles/ms";

div.modalNewPattern {
  div.MuiDialog-paper {
    width: 25rem;

    div.MuiDialogTitle-root {
      h2 {
        color: black;
        display: flex;
        align-items: center;

        span {
          margin-left: 20px;
        }
      }
    }

    div.MuiDialogContent-root {
      div.textfieldPatternName {
        margin-bottom: 30px;
      }
    }
  }

  p.MuiFormHelperText-root {
    color: red;
  }
}
